// here I can overwrite default values from `themes/uBlogger/assets/css/_variables.scss`



// ==============================
// Override Variables
// ==============================

// ========== Global ========== //
// Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700');
@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://pvinis.github.io/iosevka-webfont/3.4.1/iosevka.css');

// Font and Line Height
$global-font-family:  'Atkinson Hyperlegible', 'Piazzolla, serif;', 'Open Sans', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial !important;


//$global-font-size: 16px;
$global-font-weight: 400;
// $global-line-height: 1.5rem;

$h-font-family: Iosevka Web;
$code-font-family: Iosevka Web;
$h2-text-transformation: uppercase;
$h2-font-weight: 400;
$h2-color-dark: #FF5D62 !important;
$h1-color-dark: #DCD7BA;
$h3-font-weight: 400;
$h4-font-weight: 400;

// $global-color-accent: #2c678d;

// // Color of the background
//$global-background-color: #ffffff !default;
$global-background-color-dark: #181820 !important; //this is outer bg

// $global-background-secondary-color: #eee;
$global-background-secondary-color-dark: #1F1F28; // this is the inner bg

// $global-secondary-color: #DDD;
// $global-secondary-color-dark: #a9a9b3;

// // Color of the text
$global-font-color: #161209 !important;
$global-font-color-dark: #DCD7BA !important;

// // Color of the secondary text
// $global-font-secondary-color: #a9a9b3 !default;
// $global-font-secondary-color-dark: #939090 !default;

// // Color of the link
$global-link-color: #3150AA !default;
$global-link-color-dark: #658594 !default;

// // Color of the hover link
$global-link-hover-color: #3150AA !default;
$global-link-hover-color-dark: #D27E99 !important;

// // Color of the border
// $global-border-color: #2c678d !default;
// $global-border-color-dark: #a9a9b3 !default;
// ========== Global ========== //

// ========== Single Content ========== //
// $article-border-radius: 20px;

// // Font size of the TOC
// $toc-title-font-size: 1.2rem !default;
// $toc-content-font-size: 12px !default;

//self made
$article-single-font-size: 1.1em; //16px;
$article-single-line-height: 1.6em;
$global-font-color-visited: #3150AA !default;
$global-font-color-visited-dark: #938AA9 !default;
$single-subtitle-font-style: normal !default;


$breadcrumbs-color: #a9a9b3;
$breadcrumbs-color-dark: #717C7C !important;

// // Color of the single link
$single-link-color: #3150AA;
$single-link-color-dark: #658594;

// // Color of the hover single link
$single-link-hover-color: #3150AA !default;
$single-link-hover-color-dark: #D27E99 !important;

// // Color of the table background
// $table-background-color: #fff !default;
// $table-background-color-dark: #F35656 !default;

// // Color of the table thead
// $table-thead-color: #ededed !default;
// $table-thead-color-dark: #20252b !default;

// // Color of the blockquote
// $blockquote-color: #6bd6fd !default;
// $blockquote-color-dark: #59c5ec !default;
// ========== Single Content ========== //


// ========== newsletter form ========== //
/* CSS for desktops and larger devices */
.subscription-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* CSS for mobile devices */
@media (max-width: 768px) {
    .subscription-form {
        flex-direction: column; /* stack the children vertically */
    }

    .subscription-form input,
    .subscription-form .captcha {
        margin-bottom: 10px; /* space between stacked elements */
        width: 100%; /* make elements span the full width */
        height: 60px;
    }
}

/* Base link styles */
.highlight-link {
  position: relative;
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

/* HIGHLIGHT */
/* The highlight effect */
.highlight-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: +2px;
  width: 100%;
  height: 1.5rem;
  transform: skewY(-2.5deg);
  z-index: -1;
  transition: opacity 0.2s ease;
}

/* Light mode */
.highlight-link::after {
  background-color: rgba(101, 133, 148, 0.2); /* Based on your #658594 link color, but very transparent */
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .highlight-link::after {
    background-color: rgba(210, 126, 153, 0.2); /* Based on your #D27E99 hover color, but very transparent */
  }
  
  .highlight-link {
    color: #658594; /* your existing link color */
  }
  
  .highlight-link:hover {
    color: #D27E99; /* your existing hover color */
  }
}
